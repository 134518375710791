// element.querySelector -> element.qs
HTMLElement.prototype.qs = function(sel) {
    return this.querySelector(sel);
};

// document.querySelector -> qs
window.qs = document.querySelector.bind(document);

// element.querySelectorAll -> element.qsa
HTMLElement.prototype.qsa = function(sel) {
    return this.querySelectorAll(sel);
};

// document.querySelectorAll -> qsa
window.qsa = document.querySelectorAll.bind(document);

// element.addEventListener -> element.on
Node.prototype.on = window.on = function(eventName, fn, useCapture) {
    this.addEventListener(eventName, fn, useCapture || true);
};

// element.removeEventListener -> element.off
Node.prototype.off = window.off = function(name, fn, useCapture) {
    this.removeEventListener(name, fn, useCapture || true);
};

// element.addEventListener(eventName, fn, {capture: true}) -> element.once(eventName, fn)
Node.prototype.once = window.once = function(eventName, fn) {
    this.addEventListener(eventName, fn, { once: true });
};

// Allows you to pass a string or an HTMLElement. In the first case, it will querySelect it.
window.domEl = function(el) {
    return el instanceof HTMLElement ? el : qs(el);
};

// Allows you to pass a string or an HTMLElement. In the first case, it will querySelect it.
Node.prototype.domEl = function(el) {
    return el instanceof HTMLElement ? this.el : this.qs(el);
};

// Shortener for custom event dispatching
window._ev = function(eventName, eventData) {
    // For IE9+, you should add gt3's polyfill: https://gist.github.com/gt3/787767e8cbf0451716a189cdcb2a0d08
    document.dispatchEvent(new CustomEvent(eventName, { detail: eventData }));
};

function _scrollTo(element, to, duration) {

    // http://robertpenner.com/easing/
    // t = current time, b = start value, c = change in value, d = duration
    Math.easeInOutQuad = function(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20,
        animateScroll = function() {
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
    animateScroll();

}

function scrollTo(to, duration) {
    _scrollTo(document.documentElement, to, duration);
    _scrollTo(document.body, to, duration);
}